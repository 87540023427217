jQuery($ => {
    class Umktg_Popup {
      constructor(element) {
        this._             = element;
        this._element      = $(element);
        this._id           = this._element.attr('id');
        this._storageKey   = `${this._id}.closed`;
        this.conditionsMet = true;
  
        this.init();
      }
  
      //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
      // ✅ Initialize
      //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
  
      init() {
        // If popup has been closed, conditions fail
        if (localStorage.getItem(this._storageKey) === 'true') {
          this.conditionsMet = false;
        }

        // If current location is Useful Stories, conditions fail
        if ($('.stories-iframe')[0] || $('.stories-iframe')[0]) {
          this.conditionsMet = false;
        }

        // If set to open, conditions pass
        if (this._element.is('[class*="--open"]')) {
          this.conditionsMet = true;
        }

        this.maybeOpen();

        this._element.on('close', this.handleClose);

        this._element.trigger( 'init', this );
      }

      //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
      // ✅ Manage Close Status
      //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡

      getCloseStatus = () => {
        const closeStatus = localStorage.getItem(this._storageKey);

        return closeStatus === 'true';
      }

      setCloseStatus = (status = 'true') => {
        localStorage.setItem(this._storageKey, status);
      }

      //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
      // ✅ Maybe Open Popup
      //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡

      maybeOpen = () => {
        if (!this.conditionsMet) return this._element.remove();

        if (this._element.hasClass('m-modal')) {
          this._element.modal('handleOpen');
        } else if (this._element.hasClass('umktg-modal')) {
          this._element.umktg_modal('handleOpen');
        }
      }

      //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
      // ✅ Handle Close
      //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡

      handleClose = () => {
        this.setCloseStatus('true');
      }
    }
  
    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
    // ✅ Setup jQuery Plugin
    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
  
    $.fn.umktg_popup = function() {
      var _ = this,
          opt = arguments[0],
          args = Array.prototype.slice.call(arguments, 1),
          l = _.length,
          i,
          ret;
      for (i = 0; i < l; i++) {
        if (typeof opt == 'object' || typeof opt == 'undefined')
          _[i].umktg_popup = new Umktg_Popup(_[i], opt);
        else
          ret = _[i].umktg_popup[opt].apply(_[i].umktg_popup, args);
        if (typeof ret != 'undefined') return ret;
      }
      return _;
    }
  
    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
    // ✅ Run
    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
  
    $('.umktg-popup').umktg_popup();
});