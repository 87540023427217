jQuery($ => {
  class UmktgModal {
    constructor(element, args = {}) {
      this._            = element;
      this._element     = $(element);
      this._closeButton = this._element.find('.umktg-modal__close');
      this._overlay     = this._element.find('.umktg-modal__overlay');
      this._focusable   = this._element.find(
        'button, [href], input:not([type="hidden"]), select, textarea, [tabindex]:not([tabindex="-1"])'
      );

      this._args = $.extend({
        triggerId: this._element.data('controlledby'),
        focusOnModal: false
      }, args);

      this._trigger = $(`#${this._args.triggerId}`);

      this.init();
    }

    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
    // ✅ Initialize
    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡

    init() {
      this.setupHandlers();
      this._element.trigger('init', this);
    }

    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
    // ✅ Setup Handlers
    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡

    setupHandlers() {
      this._closeButton.on('click', this.handleCloseButtonClick);
      this._overlay.on('click', this.handleOverlayClick);
      this._element.on('blur', this.handleBlur);
      this._element.find('*').on('blur', this.handleBlur);
      this._element.on('keydown', this.handleKeydown);
    }

    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
    // ✅ Handle Open
    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡

    handleOpen = () => {
      this._element
        .addClass('umktg-modal--open')
        .attr('tabindex', '1');

      if (this._args.focusOnModal) {
        setTimeout(() => {
          this._element.focus();
        }, 100);
      } else {
        const $firstFocusable = this._focusable[0];

        if ($firstFocusable) {
          setTimeout(() => {
            $firstFocusable.focus();
          }, 100);
        }
      }

      this._element.trigger('open', this);
    }

    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
    // ✅ Handle Close
    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡

    handleClose = () => {
      this._element
        .removeClass('umktg-modal--open')
        .attr('tabindex', '-1');

      if (document.querySelectorAll(`#${this._args.triggerId}`).length > 1) {
        const openTrigger = $(`#${this._args.triggerId}.umktg-modal-trigger--open`)

        if (openTrigger) {
          openTrigger.removeClass('umktg-modal-trigger--open').focus();
        }
      } else {
        if (this._trigger[0]) {
          this._trigger
            .removeClass('umktg-modal-trigger--open')
            .focus();
        }
      }

      this._element.trigger('close', this);
    }

    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
    // ✅ Handle Blur
    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡

    handleBlur = event => {
      if (event.target.tagName === 'SELECT' || event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') return;

      if (
        event.relatedTarget === null ||
        (!$(event.relatedTarget).closest(".umktg-modal")[0] &&
          !$(event.relatedTarget).is(".umktg-modal") &&
          !$(event.relatedTarget).is(".umktg-modal-trigger__trigger"))
      ) {
        setTimeout(() => {
          if (!$(document.activeElement).closest('.umktg-modal').length) {
            if (!this._element.hasClass('umktg-modal--anchored')) {
              this.handleClose();
            }
          }
        }, 10)
      }
    }

    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
    // ✅ Handle Keydown
    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡

    handleKeydown = event => {
      switch (event.key) {
        case 'Tab':
          const $firstFocusable = this._focusable[0];
          const $lastFocusable = this._focusable[this._focusable.length - 1];

          if (
            ($(event.target).is($lastFocusable) && !event.shiftKey) ||
            ($(event.target).is($firstFocusable) && event.shiftKey)
          ) {
            event.preventDefault();

            if ($(event.target).is($lastFocusable)) {
              $firstFocusable.focus();
            } else {
              $lastFocusable.focus();
            }
          } else if ($(event.target).is(".umktg-modal")) {
            event.preventDefault();

            $firstFocusable.focus();
          }
          break;

        case 'Escape':
        case 'Esc':
          this.handleClose();
      }
    }

    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
    // ✅ Handle Close Button Click
    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡

    handleCloseButtonClick = () => {
      this.handleClose();
    }

    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
    // ✅ Handle Overlay Click
    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡

    handleOverlayClick = () => {
      this.handleClose();
    }

    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
    // ✅ Set Controlledby Value
    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡

    setControlledby = id => {
      this._args.triggerId = id;
      this._trigger = $(`#${id}`);
    }
  }

  //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
  // ✅ Setup jQuery Plugin
  //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡

  $.fn.umktg_modal = function () {
    var _ = this,
      opt = arguments[0],
      args = Array.prototype.slice.call(arguments, 1),
      l = _.length,
      i,
      ret;
    for (i = 0; i < l; i++) {
      if (typeof opt == 'object' || typeof opt == 'undefined') {
        _[i].umktg_modal = new UmktgModal(_[i], opt);
        $(_[i]).trigger('loaded', _[i].umktg_modal);
      } else {
        ret = _[i].umktg_modal[opt].apply(_[i].umktg_modal, args);
      }
      if (typeof ret != 'undefined') return ret;
    }
    return _;
  }

  //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
  // ✅ Run
  //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡

  $('.umktg-modal').umktg_modal();
});