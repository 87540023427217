jQuery($ => {
  class UmktgModalTrigger {
    constructor(element, args = {}) {
      this._ = element;
      this._element = $(element);
      this._trigger = this._element.find('.umktg-modal-trigger__trigger');
      this._triggerId = this._trigger.attr('id');

      this._args = $.extend({
        modalId: this._trigger.attr('aria-controls'),
        focusOnModal: false
      }, args);

      this._modal = $(`#${this._args.modalId}`);

      this.init();
    }

    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
    // ✅ Initialize
    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡

    init() {
      this.setupHandlers();
      this._element.trigger('init', this);
    }

    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
    // ✅ Setup Handlers
    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡

    setupHandlers() {
      this._trigger.on('mousedown', this.handleTriggerMousedown);
      this._trigger.on('keydown', this.handleTriggerKeydown);
      this._modal.on('close', this.completeModalClose);
      this._element.on('triggerClose', this.handleModalClose);
    }

    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
    // ✅ Check If Released
    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡

    checkIfReleased = () => {
      const isReleased = this._element.hasClass('umktg-modal-trigger--released');
      return isReleased;
    }

    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
    // ✅ Handle Modal Open
    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡

    handleModalOpen = () => {
      // Check if there's another identical form in the page
      const formId = this._modal.attr('data-form-id');
      const instances = document.querySelectorAll(`#gform_wrapper_${formId}`);

      if (instances.length > 1) {
        // Get the form outside the modal
        const instance = Array.from(instances).find(instance => !$.contains(this._modal, instance));

        if (instance) {
          // And scroll to it
          const y = instance.getBoundingClientRect().top + window.pageYOffset + -220;
          window.scrollTo({ top: y, behavior: 'smooth' });
          return;
        }
      }

      // Close any open modals before opening the clicked one
      $('.umktg-modal--open').each(function() {
        $(this).umktg_modal('handleClose');
      });

      const trigger_count = $(`[aria-controls*="${this._args.modalId}"]`).length;

      if (trigger_count > 1) {
        this._modal.umktg_modal('setControlledby', this._triggerId);
      }

      this._modal.umktg_modal('handleOpen');
      this._trigger.addClass('umktg-modal-trigger--open');
      this._element.trigger('open', this);
    }

    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
    // ✅ Handle Modal Close
    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡

    handleModalClose = () => {
      this._modal.umktg_modal('handleClose');
      this.completeModalClose();
    }

    completeModalClose = () => {
      this._trigger.removeClass('umktg-modal-trigger--open');
      this._element.trigger('close', this);
    }

    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
    // ✅ Handle Modal Toggle
    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡

    handleModalToggle = () => {
      if (this._modal.hasClass('umktg-modal--open')) {
        this.handleModalClose();
      } else {
        this.handleModalOpen();
      }
    }

    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
    // ✅ Handle Trigger Mousedown
    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡

    handleTriggerMousedown = () => {
      if (this.checkIfReleased()) return;

      this.handleModalToggle();
    }

    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
    // ✅ Handle Trigger Keydown
    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡

    handleTriggerKeydown = event => {
      if (this.checkIfReleased()) return;

      switch (event.key) {
        case 'Enter':
        case 'spacebar':
        case ' ':
          event.preventDefault();

          this.handleModalOpen();
          break;
      }
    }
  }

  //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
  // ✅ Setup jQuery Plugin
  //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡

  $.fn.umktg_modal_trigger = function () {
    var _ = this,
      opt = arguments[0],
      args = Array.prototype.slice.call(arguments, 1),
      l = _.length,
      i,
      ret;
    for (i = 0; i < l; i++) {
      if (typeof opt == 'object' || typeof opt == 'undefined')
        _[i].umktg_modal_trigger = new UmktgModalTrigger(_[i], opt);
      else
        ret = _[i].umktg_modal_trigger[opt].apply(_[i].umktg_modal_trigger, args);
      if (typeof ret != 'undefined') return ret;
    }
    return _;
  }

  //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
  // ✅ Run
  //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡

  $('.umktg-modal-trigger').umktg_modal_trigger();
});